import { a as BUNKER_STATUS_COLORS } from "./bunker.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    attrs: {
      "title": _vm.bunkerName
    }
  }, [_c("div", {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c("a-tag", {
    attrs: {
      "color": _vm.tagColor
    }
  }, [_vm._v(_vm._s(_vm.bunker.bunkerStatus ? _vm.bunker.bunkerStatus : "Close"))])], 1), _c("a-row", {
    staticClass: "card__content p-0"
  }, [_c("a-col", {
    staticClass: "gutter-row border-right p-2",
    attrs: {
      "span": 10
    }
  }, [_c("div", {
    staticClass: "capacity"
  }, [_c("span", {
    staticClass: "h8 neutral-10--text"
  }, [_vm._v("Full")]), _c("p", {
    staticClass: "m-0 b8"
  }, [_vm._v(_vm._s(Math.ceil(_vm.bunker.percentFull)) + "%")])]), _c("div", {
    staticClass: "percent_cap_weight"
  }, [_c("div", {
    staticClass: "full_cap neutral-3"
  }), _c("div", {
    staticClass: "percent_cap neutral-6",
    style: {
      height: _vm.percent_cap + "%"
    }
  }), _c("div", {
    staticClass: "percent"
  }, [_c("p", {
    staticClass: "m-0 p-1 b9 neutral-8--text"
  }, [_vm._v("100")]), _c("p", {
    staticClass: "m-0 p-1 b9 neutral-8--text"
  }, [_vm._v("80")]), _c("p", {
    staticClass: "m-0 p-1 b9 neutral-8--text"
  }, [_vm._v("60")]), _c("p", {
    staticClass: "m-0 p-1 b9 neutral-8--text"
  }, [_vm._v("40")]), _c("p", {
    staticClass: "m-0 p-1 b9 neutral-8--text"
  }, [_vm._v("20")]), _c("p", {
    staticClass: "m-0 pt-1 px-1 b9 neutral-8--text"
  }, [_vm._v("10")])])])]), _c("a-col", {
    staticClass: "gutter-row",
    attrs: {
      "span": 14
    }
  }, [_c("div", {
    staticClass: "bunker__detail"
  }, [_c("ul", {
    staticClass: "bunker__detail__list p-2 m-0"
  }, [_c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Used")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.currentWeight))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("In")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.in))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Out")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.out))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Room For")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.weightCap - _vm.bunker.currentWeight))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Total Cap")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.weightCap))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Date Open")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm._f("moment")(_vm.bunker.dateOpened, "DD/MM/YYYY")))])]), _c("li", {
    staticClass: "bunker__detail__list__item pb-1"
  }, [_c("div", {
    staticClass: "h8 neutral-10--text label"
  }, [_vm._v("Size")]), _c("div", {
    staticClass: "data b8"
  }, [_vm._v(_vm._s(_vm.bunker.produceSize))])])])])])], 1)], 1);
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "BunkerItem",
  props: {
    bunker: {
      type: Object,
      default: void 0
    }
  },
  data() {
    return {
      bunkerName: "Bunker " + this.bunker.bunkerName,
      percent_cap: Math.min(100, Math.ceil(this.bunker.percentFull))
    };
  },
  computed: {
    tagColor() {
      return BUNKER_STATUS_COLORS[this.bunker.bunkerStatus];
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var BunkerItem = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("a-row", [_c("a-col", {
    staticClass: "h5 header pt-2 px-5 pb-4"
  }, [_vm._v("Bunker Dashboard")])], 1), _c("div", {
    staticClass: "simple-filter mx-5 mb-3 mt-5"
  }, [_c("a-select", {
    staticClass: "w-100",
    attrs: {
      "default-value": _vm.selectedStatus
    },
    on: {
      "change": _vm.onChange
    }
  }, _vm._l(_vm.statusList, function(status, index2) {
    return _c("a-select-option", {
      key: index2,
      attrs: {
        "value": status
      }
    }, [_c("span", [_vm._v(_vm._s(status) + " Bunkers")])]);
  }), 1)], 1), _c("a-row", {
    staticClass: "bunker__container px-5",
    attrs: {
      "gutter": 24
    }
  }, _vm._l(_vm.bunkerList, function(bunker, index2) {
    return _c("a-col", {
      key: bunker.id || index2,
      staticClass: "pb-4",
      attrs: {
        "span": 6
      }
    }, [_c("bunker-item", {
      attrs: {
        "bunker": bunker
      }
    })], 1);
  }), 1)], 1);
};
var staticRenderFns$1 = [];
var BunkerDashboard_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "BunkerDashboard",
  components: {
    BunkerItem
  },
  inject: [
    "apiUrl",
    "hasFilter",
    "filterRoute",
    "hasCreate",
    "createRoute",
    "hasEdit",
    "editRoute",
    "resourceName",
    "resourceIdName",
    "crud"
  ],
  data() {
    return {
      selectedStatus: "ALL",
      statusList: ["ALL", "OPEN", "LOCKED", "CLOSE", "HOLD", "ACTIVE"]
    };
  },
  computed: {
    bunkerList() {
      if (this.selectedStatus === "ALL") {
        return this.crud.getList();
      } else if (this.selectedStatus === "CLOSE") {
        return this.crud.getList().filter((bunker) => bunker.bunkerStatus === null);
      } else if (this.selectedStatus === "ACTIVE") {
        return this.crud.getList().filter((bunker) => bunker.bunkerStatus !== "LOCKED" && bunker.bunkerStatus !== null);
      }
      return this.crud.getList().filter((bunker) => bunker.bunkerStatus === this.selectedStatus);
    }
  },
  created() {
    this.crud.fetchList();
  },
  methods: {
    onChange(value) {
      this.selectedStatus = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var BunkerDashboard = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.bunkers.dashboard",
      "api-url": _vm.apiUrl
    }
  }, [_c("bunker-dashboard")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    BunkerDashboard
  },
  data() {
    return {
      BunkerDashboard,
      apiUrl,
      itemsMenu: [
        {
          key: "bunkers",
          title: "Bunker Processing",
          path: ""
        },
        {
          key: "bunkerdashboard",
          title: "Bunker Dashboard",
          path: "/watties-grower/bunker-dashboard"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
